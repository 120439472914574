@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700;800&family=Merriweather:wght@400;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
    background-color: #111118;
    color: #ffffff;
}

section {
  height: 93vh;
}

h1 {
  font-family: Merriweather;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.5;
  padding: 0px 0px 12px 0px;
}

h2 {
  font-family: Merriweather;
  font-weight: 400;
  font-size: 32px;
  line-height: 1.5;
}

h3 {
  font-family: Merriweather;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 20px;
}

p {
  font-family: Merriweather;
  font-weight: 200;
  font-size: 20px;
  line-height: 1.5;
}

.hero-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.hero {
  width: 70%;
}

section.about-container {
  height: 100vh;
  width: 100%;
  background-color: #FDEA5A;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.about {
  color: #242728;
  width: 80%;
  display: flex;
  align-items: center;
}

.description {
  width: 50%;
  margin-right: 48px;
}

.photo {
  width: 50%;
}

.photo img {
  width: 100%;

}

.arrow {
  width: 20%;
  max-width: 300px;
  min-width: 150px;
  z-index: 1;
  animation-name: spin;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

.arrow1 {
  position: absolute;
  top: 4%;
  left: 25%;
  z-index: -1;
}

.arrow2 {
  position: absolute;
  top: 20%;
  left: 2%;
  z-index: -1;
}

.eyes {
    background-color: #fdea5a;
    width: 100px;
    height: 70px;
    margin: 0 auto;
    text-align: center;
    padding-top: 10px;
    /* animation: bounce 9s infinite; */
    border-radius: 100px 100px 0px 0px;
    cursor: pointer;
}

.eye-emoji {
  padding-top: 10px;
  height: 40px;
  width: 40px;
  background: url(https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/eyes_1f440.png) no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
  animation: flip 6s infinite;
}


@keyframes flip {
  0% {
    transform: scaleX(1)
  }

  40% {
    transform: scaleX(1)
  }

  41% {
    transform: scaleX(-1)
  }

  60% {
    transform: scaleX(-1)
  }

  61% {
    transform: scaleX(1)
  }

  100% {
    transform: scaleX(1)
  }
}

@keyframes bounce {
0% {
  transform:translateY(0px);
}

33% {
  transform:translateY(0px);
}

36% {
    transform:translateY(-75px);
}
 37% {
    transform:translateY(-65px);
}

 70% {
    transform:translateY(-65px);
}
  72% {
    transform:translateY(-75px);
}
  75% {
      transform:translateY(0px);
  }
}






@keyframes spin {
  0%   {transform: rotate(10deg);}
  50%   {transform: rotate(20deg);}
  100%   {transform: rotate(10deg);}
}


.arrow3 {
  position: absolute;
  top: 45%;
  left: 85%;
  z-index: -1;
}

.arrow4 {
  position: absolute;
  top: 10%;
  left: 60%;
  z-index: -1;
}

.arrow5 {
  position: absolute;
  top: 70%;
  left: 10%;
  z-index: -1;
}

.arrow6 {
  position: absolute;
  top: 80%;
  left: 65%;
  z-index: -1;
}

.highlight {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
  background: linear-gradient(180deg,rgba(255,255,255,0) 82%, #FFEC5B 0%);
}

.highlight:hover {
  background: linear-gradient(180deg,rgba(255,255,255,0) 70%, #CDBA29 30%);
}

.highlight2 {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
  background: linear-gradient(180deg,rgba(255,255,255,0) 90%, #37517E 30%);
}

.highlight2:hover {
  background: linear-gradient(180deg,rgba(255,255,255,0) 80%, #051F4C 30%);
}


@media (min-width: 1200px) {
  .hero {
    width: 60%;
  }
}

@media (min-width: 1500px) {
  .about {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .about {
    display: inline;
  }

  .description {
    width: 100%;
    margin-bottom: 40px;
  }

  .photo {
    width: 100%;
  }

  .photo img {
    width: 70%;
    min-width: 300px;
    max-width: 400px;
  }
}

@media (max-width: 700px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 18px;
  }

}
